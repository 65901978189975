export default [
    {
      path: '/setting/hardware/type',
      name: 'setting-hardware-type',
      component: () => import('@/views/vserv/setting/hardware/type/index.vue'),
    },
    {
      path: '/setting/hardware/brand/:typeId',
      name: 'setting-hardware-brand',
      component: () => import('@/views/vserv/setting/hardware/brand/index.vue'),      
    }, 
    {
      path: '/setting/hardware/model/:brandId',
      name: 'setting-hardware-model',
      component: () => import('@/views/vserv/setting/hardware/model/index.vue'),      
    },    
    {
      path: '/setting/hardware/warranty/type',
      name: 'setting-hardware-warranty-type',
      component: () => import('@/views/vserv/setting/hardwareWarranty/type/index.vue'),
    },
    {
      path: '/setting/hardware/warranty/brand/:typeId',
      name: 'setting-hardware-warranty-brand',
      component: () => import('@/views/vserv/setting/hardwareWarranty/brand/index.vue'),      
    }, 
    {
      path: '/setting/hardware/warranty/model/:brandId',
      name: 'setting-hardware-warranty-model',
      component: () => import('@/views/vserv/setting/hardwareWarranty/model/index.vue'),      
    },    

    //SETTING CUSTOMER
    {
      path: '/setting/customer/owner',
      name: 'setting-customer-owner',
      component: () => import('@/views/vserv/setting/customer/owner/index.vue'),
    },
    {
      path: '/setting/customer/owner/create',
      name: 'setting-customer-owner-create',
      component: () => import('@/views/vserv/setting/customer/owner/create/index.vue'),
    },
    {
      path: '/setting/customer/owner/edit',
      name: 'setting-customer-owner-edit',
      component: () => import('@/views/vserv/setting/customer/owner/create/index.vue'),
    },

    {
      path: '/setting/customer/company',
      name: 'setting-customer-company',
      component: () => import('@/views/vserv/setting/customer/company/index.vue'),
    },  
    {
      path: '/setting/customer/company/create',
      name: 'setting-customer-company-create',
      component: () => import('@/views/vserv/setting/customer/company/create/index.vue'),
    }, 
    {
      path: '/setting/customer/company/edit',
      name: 'setting-customer-company-edit',
      component: () => import('@/views/vserv/setting/customer/company/create/index.vue'),
    },  



    {
      path: '/setting/customer/sub-company',
      name: 'setting-customer-sub-company',
      component: () => import('@/views/vserv/setting/customer/subCompany/index.vue'),
    },
    {
      path: '/setting/customer/sub-company/create',
      name: 'setting-customer-sub-company-create',
      component: () => import('@/views/vserv/setting/customer/subCompany/create/index.vue'),
    }, 
    {
      path: '/setting/customer/sub-company/edit',
      name: 'setting-customer-sub-company-create',
      component: () => import('@/views/vserv/setting/customer/subCompany/create/index.vue'),
    }, 
    {
      path: '/setting/customer/branch',
      name: 'setting-customer-branch',
      component: () => import('@/views/vserv/setting/customer/branch/index.vue'),
    },
    {
      path: '/setting/customer/branch/create',
      name: 'setting-customer-branch-create',
      component: () => import('@/views/vserv/setting/customer/branch/create/index.vue'),
    },  
    {
      path: '/setting/customer/branch/edit',
      name: 'setting-customer-branch-create',
      component: () => import('@/views/vserv/setting/customer/branch/create/index.vue'),
    }, 
    {
      path: '/setting/customer/branch/stock',
      name: 'setting-customer-branch-stock',
      component: () => import('@/views/vserv/setting/customer/branchStock/index.vue'),
    },
    {
      path: '/setting/customer/branch/stock/create',
      name: 'setting-customer-branch-stock-create',
      component: () => import('@/views/vserv/setting/customer/branchStock/create/index.vue'),
    },
    {
      path: '/setting/customer/branch/stock/edit',
      name: 'setting-customer-branch-stock-edit',
      component: () => import('@/views/vserv/setting/customer/branchStock/create/index.vue'),
    },
    
    
    //CUSTOMER WARRANTY

    {
      path: '/setting/customer/warranty/owner',
      name: 'setting-customer-warranty-owner',
      component: () => import('@/views/vserv/setting/customerWarranty/owner/index.vue'),
    },
    {
      path: '/setting/customer/warranty/owner/create',
      name: 'setting-customer-warranty-owner-create',
      component: () => import('@/views/vserv/setting/customerWarranty/owner/create/index.vue'),
    },
    {
      path: '/setting/customer/warranty/owner/edit',
      name: 'setting-customer-warranty-owner-edit',
      component: () => import('@/views/vserv/setting/customerWarranty/owner/create/index.vue'),
    },

    {
      path: '/setting/customer/warranty/company',
      name: 'setting-customer-warranty-company',
      component: () => import('@/views/vserv/setting/customerWarranty/company/index.vue'),
    },  
    {
      path: '/setting/customer/warranty/company/create',
      name: 'setting-customer-warranty-company-create',
      component: () => import('@/views/vserv/setting/customerWarranty/company/create/index.vue'),
    }, 
    {
      path: '/setting/customer/warranty/company/edit',
      name: 'setting-customer-warranty-company-edit',
      component: () => import('@/views/vserv/setting/customerWarranty/company/create/index.vue'),
    },
    {
      path: '/setting/customer/warranty/sub-company',
      name: 'setting-customer-warranty-subcompany',
      component: () => import('@/views/vserv/setting/customerWarranty/subCompany/index.vue'),
    },
    {
      path: '/setting/customer/warranty/sub-company/create',
      name: 'setting-customer-warranty-sub-company-create',
      component: () => import('@/views/vserv/setting/customerWarranty/subCompany/create/index.vue'),
    }, 
    {
      path: '/setting/customer/warranty/sub-company/edit',
      name: 'setting-customer-warranty-sub-company-create',
      component: () => import('@/views/vserv/setting/customerWarranty/subCompany/create/index.vue'),
    }, 
    {
      path: '/setting/customer/warranty/branch',
      name: 'setting-customer-warranty-branch',
      component: () => import('@/views/vserv/setting/customerWarranty/branch/index.vue'),
    },
    {
      path: '/setting/customer/warranty/branch/create',
      name: 'setting-customer-branch-warranty-create',
      component: () => import('@/views/vserv/setting/customerWarranty/branch/create/index.vue'),
    },  
    {
      path: '/setting/customer/warranty/branch/edit',
      name: 'setting-customer-warranty-branch-create',
      component: () => import('@/views/vserv/setting/customerWarranty/branch/create/index.vue'),
    }, 



    {
      path: '/setting/distance/customer',
      name: 'setting-distance-customer',
      component: () => import('@/views/vserv/setting/distance/customer/index.vue'),
    },   
    {
      path: '/setting/distance/employee',
      name: 'setting-distance-employee',
      component: () => import('@/views/vserv/setting/distance/employee/index.vue'),
    },  
    {
      path: '/setting/email',
      name: 'setting-email',
      component: () => import('@/views/vserv/setting/email/index.vue'),
    },     
    {
      path: '/setting/user',
      name: 'setting-user',
      component: () => import('@/views/vserv/setting/user/index.vue'),
    }, 
    {
      path: '/setting/user/create',
      name: 'setting-user-create',
      component: () => import('@/views/vserv/setting/user/create/index.vue'),
    }, 
    {
      path: '/setting/user/edit/:userId',
      name: 'setting-user-edit',
      component: () => import('@/views/vserv/setting/user/create/index.vue'),
    },
    {
      path: '/setting/role',
      name: 'setting-role',
      component: () => import('@/views/vserv/setting/role/index.vue'),
    },
    {
      path: '/setting/role/create',
      name: 'setting-role-create',
      component: () => import('@/views/vserv/setting/role/create/index.vue'),
    },   
    {
      path: '/setting/role/edit/:roleId',
      name: 'setting-role-edit',
      component: () => import('@/views/vserv/setting/role/create/index.vue'),
    },      
  ]
  