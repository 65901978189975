
export default {
  namespaced: true,
  state: {
    searchTemp: null,  
     
  },
  getters: {
    getSearchTemp: state => {
      return state.searchTemp
    },
  },
  mutations: {
    UPDATE_SEARCH_TEMP(state, searchTemp) {
      state.searchTemp = searchTemp
    },
  },
  actions: {
   
  },
}


