import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const RoleService = () => {}

RoleService.GetRoles = async()=> {  
    return await AXIOS_SERVICE('get', `${BaseUrl}/setting/role`, {})
}

RoleService.GetRoleById = async(id)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/role/${id}`, {})
}

RoleService.GetRoleAll = async()=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/role/all`, {})
}

RoleService.InsertRole = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/role`, payload)
}

RoleService.UpdateRole = async(payload, roleId)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/role/${roleId}`, payload)
}

RoleService.DeleteRole = async(roleId)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/role/${roleId}`, {})
}


 
 export default RoleService