export default [
    {
      path: '/accounting',
      name: 'accounting',
      component: () => import('@/views/vserv/accounting/user/index.vue'),
      // meta: {
      //   pageTitle: 'Good Table',
      //   breadcrumb: [
      //     {
      //       text: 'Table',
      //     },
      //     {
      //       text: 'Good Table',
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: '/accounting/admin',
      name: 'accounting-admin',
      component: () => import('@/views/vserv/accounting/admin/index.vue'),     
    },  
    {
      path: '/accounting/admin/clearbill/:incentiveId',
      name: 'accounting-admin-clearbill',
      component: () => import('@/views/vserv/accounting/admin/clearbill/index.vue'),     
    },      
    {
      path: '/accounting/admin/approve/:incentiveId',
      name: 'accounting-admin-approve',
      component: () => import('@/views/vserv/accounting/admin/approve/index.vue'),     
    },   
   
    {
      path: '/accounting/create',
      name: 'accounting-create',
      component: () => import('@/views/vserv/accounting/user/create/index.vue'),     
    },
    {
      path: '/accounting/edit/:incentiveId',
      name: 'accounting-create',
      component: () => import('@/views/vserv/accounting/user/create/index.vue'),     
    },
    {
      path: '/accounting/clearbill/:incentiveId',
      name: 'accounting-clearbill',
      component: () => import('@/views/vserv/accounting/user/clearbill/index.vue'),      
    },
  ]
  