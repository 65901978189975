import RoleService from "@/services/setting/role"
import useJwt from '@/auth/jwt/useJwt'
import Router from '@/router/'

export default {
  namespaced: true,
  state: {
    user: {},   
  },
  getters: {
    getUserPermission: state => {
      return state.user.permission
    },
    getCustomerRolePermission: state => code=> {
      const options = state.user.options || []
      const finded = options.find(f=>f.permissionCode == code)

      return finded
    },
    getPermissionPage: state => code =>{ 
      if(state.user?.permission?.length>0){
        return state.user.permission[0][code]
      }
      return "unKnown"
    }
  },
  mutations: {
    async INIT(state){
      console.log("init permission")
      if(Object.keys(state.user).length==0){
        const userLoginData = localStorage.getItem("userData");

        if(userLoginData){
          try {
            const userData = JSON.parse(userLoginData);      
            const result = await RoleService.GetRoleById(userData.roleId)
            if(result.status=="error"){  
              alert("Session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง")         
              logout()
            }
            state.user = result.data 
          } catch (error) {  
            alert("การเข้าสู่ระบบผิดพลาด กรุณาลองใหม่อีกครั้ง")        
            logout()
          }
        }
      
      }
    },
    UPDATE_USER_PERMISSION(state, user) {
      state.user = user
    },


    
  },
  actions: {
    init ({commit}) {
      commit('INIT')
    }
  },
}


function logout(){
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  // Redirect to login page
  Router.push("/login")
}