import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/"

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
//import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import ticket from './routes/vserv/ticket'
import accounting from './routes/vserv/accounting'
import stock from './routes/vserv/stock'
import setting from './routes/vserv/setting'
import report from './routes/vserv/report'
import dashboard from './routes/vserv/dashboard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'ticket-inprogress' } },
    {
      path: '/not-authorized',
      name: 'authorized',
      component: () => import('@/views/vserv/others/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    ...ticket,
    ...accounting,
    ...stock,
    ...setting,
    ...report,
    //...apps,
    ...dashboard,
    ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

//store.dispatch('user/init')
//console.log("TEST003-",store.getters["user/getPermissionPage"]("STK003"))
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }


  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
