export default [
  {
    path: "/report/ticket",
    name: "report-ticket",
    component: () => import("@/views/vserv/report/ticket/index.vue"),
  },
  {
    path: "/report/accounting",
    name: "report-accounting",
    component: () => import("@/views/vserv/report/accounting/index.vue"),
  },
];
