export default [
    {
      path: '/ticket/inprogress',
      name: 'ticket-inprogress',
      component: () => import('@/views/vserv/ticket/inprogress/index.vue'),
      // meta: {
      //  permissionCode:""
      // },
    },
    {
      path: '/ticket/closed',
      name: 'ticket-closed',
      component: () => import('@/views/vserv/ticket/closed/index.vue'),
      // meta: {
      //   pageTitle: 'Good Table',
      //   breadcrumb: [
      //     {
      //       text: 'Table',
      //     },
      //     {
      //       text: 'Good Table',
      //       active: true,
      //     },
      //   ],
      // },
    },  
    {
      path: '/ticket/cancel',
      name: 'ticket-cancel',
      component: () => import('@/views/vserv/ticket/cancel/index.vue'),
      // meta: {
      //   pageTitle: 'Good Table',
      //   breadcrumb: [
      //     {
      //       text: 'Table',
      //     },
      //     {
      //       text: 'Good Table',
      //       active: true,
      //     },
      //   ],
      // },
    },  
    {
      path: '/ticket/create',
      name: 'ticket-inprogress-create',
      component: () => import('@/views/vserv/ticket/create/index.vue'),      
    },
    {
      path: '/ticket/edit/:ticketId',
      name: 'ticket-inprogress-create',
      component: () => import('@/views/vserv/ticket/create/index.vue'),      
    },
  ]
  