export default [
    {
      path: '/stock/vserv',
      name: 'stock-vserv',
      component: () => import('@/views/vserv/stock/vserv/index.vue'),
      // meta: {
      //   pageTitle: 'Good Table',
      //   breadcrumb: [
      //     {
      //       text: 'Table',
      //     },
      //     {
      //       text: 'Good Table',
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: '/stock/onhand',
      name: 'stock-onhand',
      component: () => import('@/views/vserv/stock/onhand/index.vue'), 
    },    
    {
      path: '/stock/warranty',
      name: 'stock-warranty',
      component: () => import('@/views/vserv/stock/warranty/index.vue'), 
    }, 
    {
      path: '/stock/repair',
      name: 'stock-repair',
      component: () => import('@/views/vserv/stock/repair/index.vue'), 
    },  
    {
      path: '/stock/repair/history',
      name: 'stock-repair-history',
      component: () => import('@/views/vserv/stock/repairHistory/index.vue'), 
    },    
    {
      path: '/stock/movement',
      name: 'stock-movement',
      component: () => import('@/views/vserv/stock/movement/index.vue'), 
    },   
    {
      path: '/stock/movement/detail',
      name: 'stock-movement-detail',
      component: () => import('@/views/vserv/stock/movement/detail.vue'), 
    },    
  ]
  