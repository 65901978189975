import axios from "axios";

export default async function AXIOS_SERVICE( method, url, data, token="",header={"Content-Type": "application/json"},isFormdata=false ) {
  let payload = JSON.stringify(data);
  if(isFormdata){
    payload = data
  }

  const accessToken = localStorage.getItem("accessToken");
  
  const config = {
    method,
    url,
    headers: {
      'Authorization': `Bearer ${accessToken}`,      
      ...header
    },
    data: payload,
  };


  try {
    const res = await axios(config);
    return {
      status: "success",
      data:res.data,
    };
  } catch (e) {
    if (e.response) {
      if(e.response.status==403){       
        window.location.href="/login"
      }
    }
    return {
      status: "error",
      message: e.message,
    };
  }
}

